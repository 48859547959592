import { createAsyncThunk } from '@reduxjs/toolkit'

import { LiitoIncident } from '../../components/incidents/types'
import { apiGET, apiPOST } from '../../lib/api'

interface IncidentsError {
  message: string
}

const fetchIncidentsEnabled = (): Promise<IncidentsEnabledResponseWrapper> =>
  apiGET('incidentsEnabled')

interface IncidentsEnabledResponseWrapper {
  incidentsEnabled: boolean
}

export const fetchIncidentsEnabledAction = createAsyncThunk<
  boolean,
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsEnabled', async (none, thunkApi) => {
  try {
    const response = await fetchIncidentsEnabled()
    return response.incidentsEnabled
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch incidents allowed',
    })
  }
})

const fetchIncidents = (): Promise<LiitoIncident[]> => apiGET('incidents')

export const fetchIncidentsAction = createAsyncThunk<
  LiitoIncident[],
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidents', async (_: void, thunkApi) => {
  try {
    return await fetchIncidents()
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch incidents',
    })
  }
})

export interface IncidentsTrainFilter {
  operatingDay: string
  trainNumbers: string[]
}

const fetchIncidentsForTrains = (params: IncidentsTrainFilter[]): Promise<LiitoIncident[]> => {
  return apiPOST('incidentsForTrains', params)
}

export const fetchIncidentsForTrainsAction = createAsyncThunk<
  LiitoIncident[],
  IncidentsTrainFilter[],
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsForTrains', async (incidentsFilters, thunkApi) => {
  try {
    const response = await fetchIncidentsForTrains(incidentsFilters)
    return response
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: `Failed to fetch incidents ${incidentsFilters}`,
    })
  }
})
