import { createSlice } from '@reduxjs/toolkit'

import {
  fetchIncidentsAction,
  fetchIncidentsEnabledAction,
  fetchIncidentsForTrainsAction,
} from '../actions/incidents'
import { LiitoIncident } from '../components/incidents/types'
import { ActionStatus } from '../types/Input'

export interface IncidentsState {
  status: ActionStatus
  incidents: LiitoIncident[]
  incidentsEnabledStatus: ActionStatus
  incidentsEnabled: boolean
  shiftIncidents: LiitoIncident[]
  shiftIncidentsStatus: ActionStatus
  error?: string
  incidentsEnabledError?: string
  shiftIncidentsError?: string
  showNotification: boolean
  updatedIncidents: string[]
}

const initialState: IncidentsState = {
  status: 'none',
  incidents: [],
  incidentsEnabledStatus: 'none',
  incidentsEnabled: false,
  shiftIncidentsStatus: 'none',
  shiftIncidents: [],
  showNotification: false,
  updatedIncidents: [],
}

export const incidentsSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.showNotification = false
    },
    addToUpdatedIncidents: (state, action) => {
      state.showNotification = true
      state.updatedIncidents = state.updatedIncidents.concat(action.payload)
    },
    removeFromUpdatedIncidents: (state, action) => {
      state.updatedIncidents = state.updatedIncidents.filter((id) => id !== action.payload)
      if (state.updatedIncidents.length === 0) {
        state.showNotification = false
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIncidentsEnabledAction.pending, (state) => {
      state.incidentsEnabledStatus = 'loading'
      state.incidentsEnabledError = undefined
    })

    builder.addCase(fetchIncidentsEnabledAction.fulfilled, (state, { payload }) => {
      state.incidentsEnabled = payload
      state.incidentsEnabledStatus = 'succeeded'
    })

    builder.addCase(fetchIncidentsEnabledAction.rejected, (state, { payload }) => {
      if (payload) {
        state.incidentsEnabledError = payload.message
      }
      state.incidentsEnabledStatus = 'failed'
    })

    builder.addCase(fetchIncidentsAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchIncidentsAction.fulfilled, (state, { payload }) => {
      state.incidents = payload
      state.status = 'succeeded'
    })

    builder.addCase(fetchIncidentsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload.message
      }
      state.status = 'failed'
    })

    builder.addCase(fetchIncidentsForTrainsAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchIncidentsForTrainsAction.fulfilled, (state, { payload }) => {
      state.shiftIncidents = payload
      state.shiftIncidentsStatus = 'succeeded'
    })

    builder.addCase(fetchIncidentsForTrainsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.shiftIncidentsError = payload.message
      }
      state.shiftIncidentsStatus = 'failed'
    })
  },
})

export const { resetNotification, addToUpdatedIncidents, removeFromUpdatedIncidents } =
  incidentsSlice.actions

export default incidentsSlice.reducer
